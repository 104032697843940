
import GetImageConversion from '../commons/ImageConversion'
import { defineComponent, reactive, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import HeaderTit from '@/components/HeaderTit.vue'
import { getStorage } from '../commons/SessionStorageUtils'
import AxiosAdapter from '../request/AxiosAdapter'
import { getRecordId } from '../commons/Utils'
import { throwCustomFunction } from '../commons/ErrorHandle'
import { ElLoading, ElMessage } from 'element-plus'
import { compress } from 'image-conversion'
import { acceptImage } from '../commons/unit'

export default defineComponent({
  name: 'Authentication',
  components: { HeaderTit },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const caseId = ref()
    const imag = ref()
    const acceptImageUr = ref(acceptImage)
    const institutionId = ref()
    onMounted(() => {
      caseId.value = route.query.caseId
    })
    const getUrl = process.env.VUE_APP_BASE_API
    const urls = reactive({
      frontUrl: '',
      reverseUrl: '',
      electronicId: route.query.id
    })
    const submit = async () => {
      getRecordId(
        route.query.id,
        '当事人列表,身份证上传提交，正面:' +
          urls.frontUrl +
          '反面:' +
          urls.reverseUrl,
        true
      )
      if (!urls.frontUrl) {
        throwCustomFunction('请上传身份证正面')
      }
      if (!urls.reverseUrl) {
        throwCustomFunction('请上传身份证反面')
      }

      await AxiosAdapter.post({
        url: '/webElectronic/electronicAuth',
        data: urls
      })
      ElMessage.success({
        type: 'success',
        message: '保存成功',
        duration: 2000
      })
      route.query.platformCode == '120'
        ? router.push({
            path: '/west-city-confirmation',
            query: {
              id: route.query.id,
              institutionId: route.query.institutionId,
              caseId: route.query.caseId,
              courtName: route.query.courtName,
              caseNumberWord: route.query.caseNumberWord,
              receiverName: route.query.receiverName,
              userName: route.query.userName,
              agentName: route.query.agentName
            }
          })
        : router.push({
            path: '/sign-in-agreement',
            query: {
              id: route.query.id,
              caseNumberWord: route.query.caseNumberWord,
              receiverName: route.query.receiverName,
              institutionId: route.query.institutionId
            }
          })
    }
    let loading: any

    const beforeUploadFun = () => {
      loading = ElLoading.service({
        lock: true,
        text: '查询中请稍等',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    }
    const gotoPage = () => {
      router.back()
    }
    const fontSuccess = (res: any) => {
      urls.frontUrl = res.value && res.value[0]
      if (!res.value && !res.value[0]) {
        ElMessage.error({
          type: 'error',
          message: '上传失败',
          duration: 2000
        })
      }
      loading.close()
    }
    const onErrorFun = (res: any) => {
      ElMessage.error({
        type: 'error',
        message: '上传失败',
        duration: 2000
      })
      loading.close()
    }
    const backSuccess = (res: any) => {
      urls.reverseUrl = res.value && res.value[0]
      if (!res.value && !res.value[0]) {
        ElMessage.error({
          type: 'error',
          message: '上传失败',
          duration: 2000
        })
      }
      loading.close()
    }

    onMounted(async () => {
      if (route.query.institutionId) {
        institutionId.value = route.query.institutionId
      }
      const data: any = await AxiosAdapter.get({
        url: '/webElectronic/getIdentityPhoto',
        data: { electronicId: route.query.id }
      })
      urls.frontUrl = data.frontUrl
      urls.reverseUrl = data.reverseUrl
    })

    const beforeUpload = async (file: any) => {
      return new Promise((resolve) => {
        const data = GetImageConversion(file)
        imag.value = data
        return resolve(data)
      })
    }
    const onchangeFun = (res: any) => {
      if (res && res.response && res.response.code == -1) {
        ElMessage.error({
          type: 'error',
          message: res.response.msg,
          duration: 2000
        })
      }
    }
    getRecordId(route.query.id, '案件列表进入上传身份正页面', true)
    return {
      onchangeFun,
      onErrorFun,
      beforeUploadFun,
      submit,
      gotoPage,
      fontSuccess,
      getStorage,
      getUrl,
      backSuccess,
      urls,
      caseId,
      beforeUpload,
      imag,
      acceptImageUr,
      institutionId
    }
  }
})
